import { currentDevice } from '../../core/utils/currentDevice'
import { getPrefix } from '../../core/utils/locale.js'
import Modal from '../../shared/modal/modal'
import { readCookie } from '../../utils/cookie_manager'
import { getLazyData } from '../../utils/get_lazy_data'

import { initializeUtagEvents } from './phone_list_analytics.js'

export const CALL_CENTER_MODAL_OPEN_EVENT = 'call-center-modal:open'
export const CALL_CENTER_MODAL_CLOSE_EVENT = 'call-center-modal:close'

if (currentDevice.isMobile) {
  import('./phone_list_mobile.scss')
} else {
  import(`./phone_list.scss`)
}
const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined
$(function () {
  let opened = false
  const callCenterLinks = document.querySelectorAll('.js-call-center-modal')

  callCenterLinks.forEach(element => {
    element.addEventListener('click', e => {
      const utagStructure = e.currentTarget.dataset.utagStructure

      const threeMoreRooms = e.target.classList.contains('js-three-more-rooms')
      openCallCenterPopup(false, threeMoreRooms, utagStructure)
    })
  })

  $(document).on('click', '.show-phone-numbers-404', function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (!opened) openCallCenterPopup(true)
  })

  $(document).on('click', '.phone-list-see-numbers', function () {
    if (!opened) openCallCenterPopup(true)
    if ($(this).data('show') == true) {
      $('.phone-list-number').hide()
      $('.phone-list-row').hide()
      $('.phone-list-email').hide()
      $('.phone-list-numbers').show()
      return true
    }
    $('.phone-list-number').show()

    hidePhoneList()
  })

  $(document).on('click', '.show-phone-numbers', function () {
    $('.mobile-call-center').trigger('click')
    $('.phone-list-see-numbers').trigger('click')
    $('.phone-list-number').hide()
    $('.phone-list-row').hide()
    $('.phone-list-email').hide()
  })

  function openCallCenterPopup(showNumbers = false, threeMoreRooms = false, utagStructure = false) {
    opened = true
    const htmlTag = document.documentElement
    const country = readCookie('x-country')
    const marketId = htmlTag.dataset.marketId
    const site = IB.is_in_microsite ? htmlTag.dataset.inMicrosite : 'iberostar'
    const force_cc = false
    const url = `${getPrefix()}/ajax_contents/lightbox_callcenter/?country=${country}&market_id=${marketId}&site=${site}&force=${force_cc}&showNumbers=${showNumbers}&three_more_rooms=${threeMoreRooms}`

    getLazyData(url, function (modalHtml) {
      Modal.showHtml({
        html: modalHtml,
        onShow: modal => {
          if (!isFhiPage) {
            const callCenterContainer = modal.querySelector('.js-call-center-content')
            ScrollLock.on()
            if (callCenterContainer) {
              callCenterContainer.classList.remove('hidden')
            }
            if (utagStructure) {
              modal.classList.add(utagStructure)
            }
            initializeUtagEvents()

            // Close modal when user opens Call Me Back Modal
            const callMeBackButton = modal.querySelector('.js-show-call-me-back-modal')
            callMeBackButton.addEventListener('click', function () {
              if (modal) {
                Modal.close()
              }
            })
          }
        },
        onClose() {
          ScrollLock.off()
          opened = false
          const fastbookingOverlay = document.querySelector('.fb-sticky-overlay')
          if (fastbookingOverlay) {
            fastbookingOverlay.remove()
          }
        },
        awaitCloseAnimation: true,
      })
    })
  }

  function hidePhoneList() {
    $('.phone-list-numbers').hide()
    $('.phone-list-row').show()
    $('.phone-list-email').show()
  }

  window.IB.phone_list = {
    openCallCenterPopup,
  }
})
