import '../../vue/mobile_menu/mobile_menu_init'
import '../../shared/ihg_button/ihg_button'

import { isPartner } from '../../../src/core/iberapi/partner_shower/partner_shower'
import { currentDevice } from '../../core/utils/currentDevice'

$(function () {
  const $body = $('body')
  let scrollTimer = 0
  let last_scroll_top = 0
  const delta = 5
  let navbar_height = 0
  let $block = null
  const in_hotel_page = $('body').data('page-type') === 'hotel'
  const in_home_page = $('body').data('page-type') === 'home'
  const in_destination_page = $('body').data('page-type') === 'destination'
  const in_destination_index = $('body').data('page-type') === 'destination-index'
  const in_offer_page = $('body').data('page-type') === 'offer'
  const in_offer_page_index = $('body').data('page-type') === 'offer-index'
  const in_data_page = $('body').data('page-type') === 'page'
  const in_mobile = currentDevice.isMobile || currentDevice.isLikeMobile

  function init() {
    if (currentDevice.isDesktop) return // in desktop or small desktop

    $block = in_mobile ? $('.mobile-header') : $('.main-header')

    if (!$block.length) return

    navbar_height = in_mobile ? $block.outerHeight() + $block.offset().top : $block.outerHeight()

    $(window).on('scroll', function () {
      const scroll = $(window).scrollTop()

      if (in_mobile && in_hotel_page) {
        // element to show sticky header with fastbooking after rebasing
        const $delta = $('.js-header-image')

        navbar_height = $delta.offset().top + $delta.outerHeight()
      }

      if (scrollTimer) {
        clearTimeout(scrollTimer)
      }

      // Make sure they scroll more than delta
      if (Math.abs(last_scroll_top - scroll) <= delta) return

      // Return if scroll is detected after opening/closing mobile menu
      if (document.body.classList.contains('opened-menu')) return

      // Using a buffer so callback it's not called too often.
      scrollTimer = setTimeout(function () {
        hasScrolled()
        if (isPartner()) togglePartnerLogo()
      }, 25)
    })

    if (in_mobile) {
      checkIfHeaderBlockHasPreviousTIROCountdown()
    }

    // Here was a $user_link.removeAttr('data-remote') when the user was logged. I've moved to browsing_session so it works even when remove_browsing is set

    // Show partner logo
    IB.partner.showPartnerLogo()
  }

  function checkIfHeaderBlockHasPreviousTIROCountdown() {
    const headersToWatch = ['.js-header-customized', '.landing-typekit-header', '.hotel-header-container']

    const header = document.querySelector(headersToWatch.join(', '))

    if (!header) return

    if (header.previousElementSibling?.classList.contains('js-tiro-countdown')) {
      document.querySelector('body').classList.remove('transparent-header', 'hotel-one-landing-page')
      document.querySelector('.mobile-header .logo-white').classList.add('hidden')
    }
  }

  function hasScrolled() {
    const st = $(window).scrollTop()
    const $banner_download_pwa = $('.banner-download-pwa')
    const $internal_nav = $('.internal-nav-cnt-nfh')
    const $header_sticky = $('#header-sticky')
    const header_height = $('.mobile-header').height()

    // the scroll is on top of the page and scroll lock is off
    if (
      st <= navbar_height &&
      (in_hotel_page ||
        in_home_page ||
        in_destination_page ||
        in_offer_page ||
        in_offer_page_index ||
        in_destination_index ||
        in_data_page) &&
      !$body.hasClass('scroll-lock-on')
    ) {
      $block
        .removeClass('nav-down sticky')
        .removeClass('nav-up')
        .addClass('initial')
        .removeClass('nav-up-after-initial')
      return
    }

    // Make sure they scroll more than delta
    if (Math.abs(last_scroll_top - st) <= delta) return

    if (st >= last_scroll_top && st > header_height) {
      // scrolling down
      if (currentDevice.isTablet) $('.fastbooking').removeClass('nav-down sticky')
      if ($banner_download_pwa.length) $banner_download_pwa.removeClass('nav-down sticky').addClass('nav-up')
      if ($('.header-card').hasClass('fixed')) $('.header-card.fixed').css('top', 0)
      $internal_nav.removeClass('nav-down sticky').addClass('nav-up')
      $header_sticky.removeClass('nav-down sticky').addClass('nav-up')

      if ($block.hasClass('initial')) {
        $block.removeClass('nav-down sticky').removeClass('initial').addClass('nav-up-after-initial')
      } else {
        $block.removeClass('nav-down sticky').removeClass('initial').addClass('nav-up')
      }
    } else {
      // scrolling up
      $block.removeClass('nav-up').removeClass('initial').addClass('nav-down sticky')
      if (IB.currentDevice === 'tablet') $('.fastbooking').addClass('nav-down sticky')
      $internal_nav.removeClass('nav-up').addClass('nav-down sticky')
      $header_sticky.removeClass('nav-up').addClass('nav-down sticky')
      if ($banner_download_pwa.length) $banner_download_pwa.removeClass('nav-up').addClass('nav-down sticky')

      $block
        .removeClass('nav-up')
        .removeClass('nav-up-after-initial')
        .removeClass('initial')
        .addClass('nav-down sticky')
    }

    last_scroll_top = st
  }

  function togglePartnerLogo() {
    const containerId = {
      tablet: '#partner-logo',
      mobile: '#partner-logo-mobile',
      desktop: '#partner-logo',
    }

    if (in_home_page || in_hotel_page) {
      if (!$block.hasClass('initial')) {
        $(`${containerId[IB.currentDevice]} .partner-logo-item-menu`).removeClass('is-hidden')
        $(`${containerId[IB.currentDevice]} .partner-logo-item`).addClass('is-hidden')
      } else {
        $(`${containerId[IB.currentDevice]} .partner-logo-item`).removeClass('is-hidden')
        $(`${containerId[IB.currentDevice]} .partner-logo-item-menu`).addClass('is-hidden')
      }
    }
  }

  IB.mobile_header = {
    init,
  }
})

$(function () {
  IB.mobile_header.init()
})
